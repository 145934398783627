import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, Typography, Snackbar, Alert, IconButton, Box, Container
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { changeApplicationStatusApi, getApplicantResumeApi, getApplicantsApi } from '../../../Api/EmployersApi';
import { useParams } from 'react-router-dom';
import MediaViewer from '../../DocsViewer/MediaViewer';

const Application = () => {
  const [view, setView] = useState(false);
  const [resumeUrl, setResumeUrl] = useState('');
  const [applications, setApplications] = useState([]);
  const [job, setJob] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const { jobid } = useParams();

  useEffect(() => {
    const fetchApplications = async () => {
      const response = await getApplicantsApi({ jobID: jobid });
      if (response && response.status === 'success') {
        setApplications(response.applications);
        setJob(response.job[0]);
      }
    };
    fetchApplications();
  }, [jobid]);

  const handleGetResume = async (userID) => {
    const response = await getApplicantResumeApi(userID);
    if (response && response.status === 'success') {
      setResumeUrl(response.file);
      setView(true);
    }
  };

  const handleAction = async (applicationID, action) => {
    const response = await changeApplicationStatusApi(applicationID, action);
    if (response && response.status === 'success') {
      setSnackbar({
        open: true,
        message: `Application ${action === 'accept' ? 'accepted' : 'rejected'} successfully`,
        severity: 'success',
      });
      const updatedApplications = applications.map((app) =>
        app._id === applicationID ? { ...app, status: action === 'accept' ? 'accepted' : 'rejected' } : app
      );
      setApplications(updatedApplications);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
          p: 2,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography variant="h4" gutterBottom>{job.jobTitle}</Typography>
        <Box>
          <Typography variant="body1">Company: {job.companyName}</Typography>
          <Typography variant="body1">Location: {job.city}</Typography>
          <Typography variant="body1">Type: {job.jobType}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          overflow: 'auto',
          maxHeight: '70vh',
          boxShadow: 3,
          borderRadius: 2,
          p: 2,
          backgroundColor: 'background.paper',
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Title</TableCell>
                <TableCell>Experience</TableCell>
                <TableCell>Resume</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((application) => (
                <TableRow key={application._id}>
                  <TableCell>{`${application.firstName} ${application.lastName}`}</TableCell>
                  <TableCell>{application.phoneNumber}</TableCell>
                  <TableCell>{application.email}</TableCell>
                  <TableCell>{application.currentjobTitle}</TableCell>
                  <TableCell>{application.experience} years</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleGetResume(application.userID)}>
                      <PictureAsPdfIcon color="primary" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{application.status}</TableCell>
                  <TableCell>
                    {application.status === 'applied' ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAction(application._id, 'accept')}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleAction(application._id, 'reject')}
                        >
                          Reject
                        </Button>
                      </>
                    ) : application.status === 'rejected' ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAction(application._id, 'accept')}
                        >
                          Accept
                        </Button>
                        <Button variant="contained" color="secondary" disabled>
                          Rejected
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button variant="contained" color="primary" disabled>
                          Accepted
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleAction(application._id, 'reject')}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {view && resumeUrl && <MediaViewer pdf={resumeUrl} setview={setView} />}
    </Container>
  );
};

export default Application;
