import axios from 'axios';
import { BaseAxiosConfig, BaseURL, ResumeAxiosConfig } from "./BaseConfig"

//=====================get dashboard====================
export const getDashboardApi = async () => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.get(`${BaseURL}/dashboard`, config);
        return response.data;
    } catch (error) {
        console.log(error);
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================Add Company====================
export const addComanyApi = async (data) => {
    try {
        const config = await ResumeAxiosConfig()
        const response = await axios.post(`${BaseURL}/company`,
            data,
            config);
        return response.data;
    } catch (error) {

        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================Get Company====================
export const getComanyApi = async (filter) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/get_company`,
            filter,
            config);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================edit Company====================
export const updateComanyApi = async (data) => {
    try {
        const config = await ResumeAxiosConfig()
        const response = await axios.put(`${BaseURL}/company`,
            data,
            config);
        return response.data;
    } catch (error) {
        console.log(error);
        return { status: 'error', message: 'Failed to fetch resume' };
    }


}
//=====================delete Company====================
export const deleteComanyApi = async (id) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.delete(`${BaseURL}/company/${id}`,
            config);
        return response.data;
    } catch (error) {
        console.log(error);
        return { status: 'error', message: 'Failed to fetch resume' };
    }


}

export const getCompanyImage = async (companyId) => {
    try {
        const config = await BaseAxiosConfig();
        const response = await axios.get(`${BaseURL}/company_image/${companyId}`, {
            ...config,
            maxBodyLength: Infinity,
            responseType: 'blob'
        });

        const image = new Blob([response.data], { type: response.data.type });
        const fileURL = URL.createObjectURL(image);
        return { status: "success", file: fileURL };
    } catch (error) {
        console.error('Error fetching company image:', error);
        return { status: 'error', message: 'Failed to fetch company image' };
    }
};



//=====================get Job====================
export const getJobApi = async (filter) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/get_job`,
            filter,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================Add Job====================
export const addJobApi = async (data) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/job`,
            data,
            config);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================update Job====================
export const updateJobApi = async (id, data) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.put(`${BaseURL}/job/${id}`,
            data,
            config);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================delete Job====================
export const deleteJobApi = async (id) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.delete(`${BaseURL}/job/${id}`,
            config);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================get Job====================
export const getApplicantsApi = async (filter) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/get_applicants`,
            filter,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}
//=====================get Job====================
export const changeApplicationStatusApi = async (appId, action) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/changeApplicationStatus`,
            { applicationID: appId, set: action },
            config);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}

//=====================get Job====================
// Function to fetch the current resume
export const getApplicantResumeApi = async (userID) => {
    try {
        const config = await BaseAxiosConfig();
        const response = await axios.post(`${BaseURL}/get_applicantsResume`,
            { userID },
            { ...config, maxBodyLength: Infinity, responseType: 'blob' });
        const resume = new Blob([response.data], { type: response.data.type }); // Adjust type based on your image type
        const fileURL = URL.createObjectURL(resume);
        return { status: "success", file: fileURL };
    } catch (error) {
        console.error('Error fetching resume:', error);
        return { status: 'error', message: 'Failed to fetch resume' };
    }
};

