import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Typography,
    Paper,
    Grid,
    Container,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteComanyApi, getComanyApi, updateComanyApi, getCompanyImage } from '../../Api/EmployersApi';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RichTextEditor from '../QuillTextEditor/QuillTextEditor';

const RootPaper = styled(Paper)({
    width: '80%',
    maxWidth: 800,
    padding: '32px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    margin: 'auto',
});

const CenteredContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
});

const ImagePreview = styled('img')({
    width: 150,
    height: 150,
    borderRadius: 8,
    objectFit: 'cover',
    marginBottom: 16,
});

const PreviewBox = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '32px',
});

const DetailBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
});

const CompanyName = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#1976d2',
});

const WebsiteText = styled(Typography)({
    fontSize: '1.1rem',
    color: '#dc004e',
});

const AboutText = styled(Typography)({
    fontSize: '1rem',
    color: '#555',
});

const LocationText = styled(Typography)({
    fontSize: '1.1rem',
    color: '#666',
});

const AddressText = styled(Typography)({
    fontSize: '1rem',
    color: '#777',
});

const ButtonWrapper = styled('div')({
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
});

const EditCompanyForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [companyName, setCompanyName] = useState('');
    const [website, setWebsite] = useState('');
    const [about, setAbout] = useState('');
    const [location, setLocation] = useState('');
    const [address, setAddress] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [urls, setUrls] = useState([]);
    const [tempImage, setTempImage] = useState(null);
    const navigate = useNavigate();
    const { companyId } = useParams();

    useEffect(() => {
        const fetchCompany = async () => {
            const response = await getComanyApi({ companyId });
            if (response && response.status === 'success') {
                const company = response.companies[0];
                setCompanyName(company.companyName);
                setWebsite(company.website);
                setAbout(company.about);
                setLocation(company.location);
                setAddress(company.address);
                setUrls(company.urls ? JSON.parse(company.urls) : []);
                if (company.image) {
                    const imageResponse = await getCompanyImage(company._id);
                    if (imageResponse && imageResponse.status === 'success') {
                        const imageUrl = imageResponse.file;
                        setProfileImage(imageUrl);


                    }
                }
            }
        };

        fetchCompany();
    }, [companyId]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAddLink = () => {
        setUrls([...urls, { type: '', link: '' }]);
    };

    const handleRemoveLink = (index) => {
        const updatedUrls = urls.filter((_, i) => i !== index);
        setUrls(updatedUrls);
    };

    const handleLinkChange = (index, field, value) => {
        const updatedUrls = urls.map((url, i) =>
            i === index ? { ...url, [field]: value } : url
        );
        setUrls(updatedUrls);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const newImageUrl = URL.createObjectURL(file);
            setTempImage(newImageUrl);
            setProfileImage(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('companyId', companyId);
        formData.append('companyName', companyName);
        formData.append('website', website);
        formData.append('about', about);
        formData.append('location', location);
        formData.append('address', address);
        if (profileImage && typeof profileImage !== 'string') {
            formData.append('image', profileImage);
        }
        formData.append('urls', JSON.stringify(urls));

        const response = await updateComanyApi(formData);
        if (response && response.status === 'success') {
            navigate(`/viewcompany/${companyId}`);
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this company?')) {
            const response = await deleteComanyApi(companyId);
            if (response && response.status === 'success') {
                navigate('/viewcompanies');
            }
        }
    };

    return (
        <CenteredContainer>
            <RootPaper elevation={3}>
                <Typography variant="h5" gutterBottom align="center">
                    {activeStep === 0 ? 'Edit Company Information' : 'Preview & Submit'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    {activeStep === 0 && (
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Company Name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Website"
                                        value={website}
                                        onChange={(e) => setWebsite(e.target.value)}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" onClick={handleAddLink} startIcon={<AddIcon />}>
                                        Add Link
                                    </Button>
                                </Grid>
                                {urls.map((url, index) => (
                                    <Grid container spacing={2} key={index} style={{ marginTop: '10px', marginLeft: '0px' }}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel>Type</InputLabel>
                                                <Select
                                                    value={url.type}
                                                    onChange={(e) => handleLinkChange(index, 'type', e.target.value)}
                                                    label="Type"
                                                    required
                                                >
                                                    <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                                                    <MenuItem value="GitHub">GitHub</MenuItem>
                                                    <MenuItem value="Other">Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Link"
                                                value={url.link}
                                                onChange={(e) => handleLinkChange(index, 'link', e.target.value)}
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => handleRemoveLink(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <RichTextEditor
                                        value={about}
                                        onChange={(value) => setAbout(value)}
                                        heading='About'
                                        height="250px"
                                        width="100%"
                                    />
                                    {/* <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="About"
                                        value={about}
                                        onChange={(e) => setAbout(e.target.value)}
                                        variant="outlined"
                                        required
                                    /> */}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Location"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                {tempImage && (
                                    <Grid item xs={12}>
                                        <ImagePreview src={tempImage} alt="Company Logo" />
                                    </Grid>
                                )}
                                {profileImage && !tempImage && (
                                    <Grid item xs={12}>
                                        <ImagePreview src={profileImage} alt="Company Logo" />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                </Grid>
                            </Grid>
                            <ButtonWrapper>
                                <Button variant="contained" onClick={() => { navigate(`/viewcompany/${companyId}`) }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                    Next
                                </Button>
                                <Button variant="contained" color="error" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </ButtonWrapper>
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box>
                            <PreviewBox>
                                {profileImage && typeof profileImage === 'string' && (
                                    <ImagePreview src={profileImage} alt="Company Logo" />
                                )}
                                {tempImage && (
                                    <ImagePreview src={tempImage} alt="Company Logo" />
                                )}
                                <DetailBox>
                                    <CompanyName>{companyName}</CompanyName>
                                    <WebsiteText>Website: {website}</WebsiteText>
                                    {urls.map((url, index) => (
                                        <Typography key={index}>{`${url.type}: ${url.link}`}</Typography>
                                    ))}
                                    <AboutText>
                                    <div dangerouslySetInnerHTML={{ __html: about }} />

                                    </AboutText>
                                    <LocationText>Location: {location}</LocationText>
                                    <AddressText>Address: {address}</AddressText>
                                </DetailBox>
                            </PreviewBox>
                            <ButtonWrapper>
                                <Button onClick={handleBack}>Back</Button>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                            </ButtonWrapper>
                        </Box>
                    )}
                </form>
            </RootPaper>
        </CenteredContainer>
    );
};

export default EditCompanyForm;
