// RichTextEditor.js
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';

const QuillContainer = styled('div')(({ height, width }) => ({
    margin: '10px 0',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: width || '100%',
    '.ql-container': {
        borderRadius: '8px',
        height: height || 'auto',
        boxSizing: 'border-box'
    },
}));

const Heading = styled('h4')(({ margin }) => ({
    margin: margin || '0 0 10px 0',
    fontSize: '1.9 rem',
    fontWeight: 'bold',
}));

const RichTextEditor = ({ value, onChange, height, width, heading }) => {
    const modules = {
        toolbar: {
            container: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image', 'video'],
                ['clean'],
            ],
        }
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color', 'background'
    ];

    return (
        <div>
            {heading && <Heading>{heading} :</Heading>}
            <QuillContainer height={height} width={width}>
                <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    modules={modules}
                    formats={formats}
                />
            </QuillContainer>
        </div>
    );
};

export default RichTextEditor;
