import { envc } from "../config";

const { protocol, hostname, port } = window.location;

const defaultIP = 'localhost';
const defaultPort = '9696';

const backendIP = hostname === 'localhost' ? defaultIP : hostname;
// const backendPort = port || defaultPort;
const backendPort = defaultPort;



// Construct the BaseURL and webSocketUrl
export const BaseURL = `${envc.API_URL}`;
// export const BaseURL = `${protocol}//${backendIP}:${backendPort}/api`;
// export const BaseURL = `https://jobs.skillssprint.com/api`;
// export const BaseURL = `http://48.217.121.138:9696/api`;
// export const webSocketUrl = `ws://${backendIP}:${backendPort}`;


export const BaseAxiosConfig = async () => {
  const token = localStorage.getItem("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axiosConfig;
};


export const ResumeAxiosConfig = async () => {
  const token = localStorage.getItem('token');
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      // Accept: 'application/pdf', // Specify PDF content type
      Authorization: `Bearer ${token}`,
    },
  };
  return axiosConfig;
};