import axios from 'axios';
import { BaseAxiosConfig, BaseURL, ResumeAxiosConfig } from './BaseConfig';


//=============================student Validate==============================

export const studentValidator = async (encoded_ip, encoded_userid) => {
    try {
        const response = await axios.post(`${BaseURL}/student_validation`,
            { encoded_ip, encoded_userid });

        // console.log(response);
        return response.data;
    } catch (error) {
        return { Error: error.message };
    }
};

//=====================Get Company====================
export const getAllComanyApi = async (filter) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/getAllCompanies`,
            { filter },
            config);
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error)
        return { status: 'error', message: 'Failed to fetch resume' };
    }
}
//=====================get jobs====================
export const getAllJobsApi = async (filter) => {
    try {
        const config = await BaseAxiosConfig();
        const response = await axios.post(`${BaseURL}/getAllJob`,
            filter,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

//=====================Apply jobs====================
export const applyJobsApi = async (data) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/job_apply`,
            data,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

//=====================get Apply jobs====================
export const getapplyedJobsApi = async () => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.get(`${BaseURL}/applied_jobs`,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

//=====================save jobs====================
export const saveJobsApi = async (jobid) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/job_save/${jobid}`,
            {},
            config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

//=====================get saved  jobs====================
export const getsaveJobsApi = async (filter) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.post(`${BaseURL}/getsaved_jobs`,
            filter,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
//=====================unsave  jobs====================
export const unsaveJobsApi = async (jobid) => {
    try {
        const config = await BaseAxiosConfig()
        const response = await axios.delete(`${BaseURL}/job_save/${jobid}`,
            config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

// Function to fetch the current resume
export const getResumeApi = async () => {
    try {
        const config = await BaseAxiosConfig();
        const response = await axios.get(`${BaseURL}/resume`,

            { ...config, maxBodyLength: Infinity, responseType: 'blob' });
        // return response.data;
        const resume = new Blob([response.data], { type: response.data.type  }); // Adjust type based on your image type
        const fileURL = URL.createObjectURL(resume);
        return {status:"success",file:fileURL};
    } catch (error) {
        console.error('Error fetching resume:', error);
        return { status: 'error', message: 'Failed to fetch resume' };
    }
};

// Function to add a new resume
export const addResumeApi = async (formData) => {
    try {
        const config = await ResumeAxiosConfig();
        const response = await axios.post(`${BaseURL}/resume`, formData, config);
        return response.data;
    } catch (error) {
        console.error('Error adding resume:', error);
        return { status: 'error', message: 'Failed to add resume' };
    }
};

// Function to update the current resume
export const updateResumeApi = async (formData) => {
    try {
        const config = await ResumeAxiosConfig();
        const response = await axios.put(`${BaseURL}/resume`, formData, config);
        return response.data;
    } catch (error) {
        console.error('Error updating resume:', error);
        return { status: 'error', message: 'Failed to update resume' };
    }
};

// Function to delete the current resume
export const deleteResumeApi = async () => {
    try {
        const config = await BaseAxiosConfig();
        const response = await axios.delete(`${BaseURL}/resume`, config);
        return response.data;
    } catch (error) {
        console.error('Error deleting resume:', error);
        return { status: 'error', message: 'Failed to delete resume' };
    }
};