import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Container,
    Typography,
    Card,
    CardMedia,
    Grid,
    Button,
    Box,
    Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { getComanyApi } from '../../Api/EmployersApi';
import { getCompanyImage } from '../../Api/EmployersApi';

const StyledContainer = styled(Container)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    width: 160,
    height: 160,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
}));

const CompanyDetails = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));



const ViewCompany = () => {
    const [companies, setCompanies] = useState([]);
   
    const navigate = useNavigate();
    const{companyId}=useParams();
    const userID = localStorage.getItem('userID');

    useEffect(() => {
        const fetchCompanies = async () => {
    
            const response = companyId ? await getComanyApi({companyId}) : await getComanyApi();

            if (response && response.status === 'success') {
                const companiesWithImages = await Promise.all(
                    response.companies.map(async (company) => {
                        if (company.image) {
                            const imageResponse = await getCompanyImage(company._id);
                            if (imageResponse.status === 'success') {
                                return { ...company, imageUrl: imageResponse.file };
                            }
                        }
                        return company;
                    })
                );
                setCompanies(companiesWithImages);
            }
        };

        fetchCompanies();
    }, [companyId]);

    const handleEditClick = (id) => {
        navigate(`/editcompany/${id}`);
    };

    return (
        <StyledContainer>
            <Grid container spacing={3}>
                {companies.map((company) => (
                    <Grid item xs={12} key={company._id}>
                        <StyledCard>
                            {company.imageUrl && (
                                <StyledCardMedia
                                    component="img"
                                    image={company.imageUrl}
                                    title={company.companyName}
                                />
                            )}
                            <CompanyDetails>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {company.companyName}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                <div dangerouslySetInnerHTML={{ __html: company.about }} />
                                </Typography>
                                <Divider />
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <strong>Address:</strong> {company.address}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <strong>Location:</strong> {company.location}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <strong>Website:</strong> <a href={`http://${company.website}`} target="_blank" rel="noopener noreferrer">{company.website}</a>
                                </Typography>
                                {company.urls && JSON.parse(company.urls).map((url, index) => (
                                    <Typography key={index} variant="body2" color="textSecondary" component="p">
                                        <strong>{url.type}:</strong> <a href={`http://${url.link}`} target="_blank" rel="noopener noreferrer">{url.link}</a>
                                    </Typography>
                                ))}
                                {userID===company.userID && (
                                    <Button variant="contained" color="primary" onClick={() => handleEditClick(company._id)}>
                                        Edit Company
                                    </Button>
                                )}
                            </CompanyDetails>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
        </StyledContainer>
    );
};

export default ViewCompany;
