import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useState } from 'react';
import { generateDemoJobs } from '../jobdemodata';
import { useParams } from 'react-router-dom';
import ApplyForm from './ApplyForm/ApplyForm';
import StarRatings from 'react-star-ratings';
import { getAllJobsApi } from '../../../Api/StudentsApi';
import { useGlobalContext } from '../../../Context';

const MainApplyDiv = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
`
const LeftApplyDiv = styled.div`
    width: 50%;
  .RSPBprogressBar {
    width: 100%;
    height: 7px;
    background: navy;
    border-radius: 12px;
    margin: 50px 0 10px;
    padding: 0;
}
`
const RightApplyDiv = styled.div`
    width: 50%;
    max-width: 600px;
    // position: sticky !important;
  
    // background: rgb(250, 249, 248);
    .detailHolder{
        position: sticky !important;
          top: 100px !important;
        background-color:rgb(255,255,255);
        width:90%;
        box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px ;
        margin:auto;
        border:1px solid rgba(0,0,0,.4);
        border-radius:8px;
        padding:16px;
        margin-top:100px;
    }
    .line{
        width:100%;
        height:1px;
        background-color:rgba(0,0,0,.3);
        margin:auto;
        margin-bottom:20px;
    }
    .detailHolder p{
        font-size:12px;
        font-weight:500;
        color:rgba(0,0,0,.6)
    }
    .detailHolder ul>li{
        margin-bottom:10px;
        font-size:15px;
    }
`


const MainApply = () => {
    const [job, setjob] = useState();
    const [right, setRight] = useState(true)
    const { jobID } = useParams();
    const { setIsLoading } = useGlobalContext();


    useEffect(() => {
        const fetchJob = async () => {
            // setIsLoading(true);
            const response = await getAllJobsApi({ _id: jobID })

            if (response && response.status === 'success') {
                // console.log(response);
                setjob(response.jobs[0]);

            }
            // setIsLoading(false);
        };
        fetchJob();
    }, [])


    return (
        <>

            {job && <MainApplyDiv>
                <LeftApplyDiv>

                    <ApplyForm setRight={setRight} job={job} />

                </LeftApplyDiv>

                {right && <RightApplyDiv>
                    <div className="detailHolder">
                        < div className='jobDetails-container'>
                            <div className="jobHeadings">
                                <div className="titlee" >
                                    <h3 >{job.jobTitle}</h3>
                                </div>
                                <div className="c">
                                    <h4>{job.companyName}</h4>
                                    <p className="rating">
                                        <StarRatings
                                            rating={5}
                                            starDimension="13px"
                                            starSpacing="1px"
                                        />
                                    </p>
                                </div>
                                <h4>{job.city}, {job.state}</h4>
                            </div>
                            <div className="detailed">
                                <div id="jobDescriptionText" className="jobsearch-jobDescriptionText">
                                    <p>Job Description:</p>
                                    <p>
                                        <strong>Description:</strong> {job.description} <br />
                                        <strong>Location:</strong> {job.city}, {job.state}, {job.country} <br />
                                        <strong>Job Type:</strong> {job.jobType} <br />
                                        <strong>Work Mode:</strong> {job.remote} <br />
                                        <strong>Required Language:</strong> {job.language} <br />
                                        <strong>Required Education:</strong> {job.education} <br />
                                        <strong>Required Experience:</strong> {job.experience} years <br />
                                        <strong>Required Skills:</strong> {job.requiredSkills} <br />
                                        <strong>Salary:</strong> {job.salary} <br />
                                        <strong>Date Posted:</strong> {new Date(job.date).toLocaleDateString()} <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </RightApplyDiv>
                }
            </MainApplyDiv>

            }

        </>
    )
}

export default MainApply
