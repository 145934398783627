import React, { useState, useRef, useEffect } from 'react';
import { ProgressBar } from 'react-step-progress-bar';
import "react-step-progress-bar/styles.css";
import { MdInfo } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { CgFileRemove } from "react-icons/cg";
import { FaFileSignature } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import './ApplyForm.css';
import ReviewPage from '../Review/ReviewPage';

const ApplyForm = ({ setRight, job }) => {
    // const { _id, jobTitle } = job;
    let userID = localStorage.getItem('userID');
    const [formData, setFormData] = useState({});
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)
    const [step4, setStep4] = useState(false)
    const [review, setReview] = useState(false)
    const [coverLetter, setCoverLetter] = useState(true);
    const inputRef = useRef()
    const navigate = useNavigate();

    // const comp = JSON.parse(localStorage.getItem('appliedJob')).companyName
    useEffect(() => {
        const { _id} = job;
        const firstname = localStorage.getItem('firstname');
        const lastname = localStorage.getItem('lastname');
        let userID = localStorage.getItem('userID');
        setFormData({
            ...formData,
            firstName: firstname,
            lastName: lastname,
            email: userID,
            jobID: _id 
        });
    }, []);





    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target

        setFormData({ ...formData, [name]: value,})

    }

    const handleContinue1 = (event) => {
        event.preventDefault();
        setStep1(!step1)
        setStep2(!step2)
    }

    const handleContinue2 = (e) => {
        e.preventDefault();
        setStep2(!step2)
        setStep3(!step3)
    }

    const handleContinue3 = (event) => {
        event.preventDefault();
        setStep3(!step3)
        setStep4(!step4)
    }

    const handleReview = (e) => {
        e.preventDefault();
        setStep3(!step3)
        setReview(true)
        console.log(formData);
        // setRight(false) 

    }
    const handlereviewback = (e) => {
        setStep3(true);
        setReview(false);
        setRight(true);
    }

    const handleChoose = () => {
        inputRef.current.click();
    }

    const handleCoverLetter = () => {
        setCoverLetter(!coverLetter)
    }

    return (
        <>
            {step1 && <div className="common-container ">
                <ProgressBar percent={25} unfilledBackground="gray" filledBackground="navy" className="progress-bar" />

                <h5>Application step 1 of 4</h5>
                <h4 style={{ fontWeight: 600, margin: "19px 0" }}>Add your contact information</h4>
                <form onSubmit={handleContinue1}>
                    <label htmlFor="firstName">First name</label>
                    <input type="text"
                        name="firstName"
                        value={formData.firstName}
                        required
                        onChange={handleChange} />
                    <label htmlFor="lastName">Last name</label>
                    <input type="text"
                        name="lastName"
                        value={formData.lastName}
                        required
                        onChange={handleChange} />
                    <label htmlFor="phoneNumber">Phone number</label><small>(optional)</small>
                    <input type="text" name="phoneNumber" onChange={handleChange} />
                    <h4>Email :<span>{formData.email}</span></h4>
                    <label htmlFor="city">City, State </label><small>(optional)</small>
                    <input type="text" name="city" onChange={handleChange} />
                    <div className="form-actions">
                        <h5 className="return-link" onClick={() => { navigate('/') }}>Return to job search</h5>
                        <button type='submit'>Continue</button>
                    </div>
                </form>
            </div>}

            {/* {step2 && <div className="common-container ">
                <ProgressBar percent={40} unfilledBackground="gray" filledBackground="navy" className="progress-bar" />

                <div className="step-header">
                    <BiArrowBack id="arrow" onClick={handleContinue1} />
                    <h5>Application step 2 of 5</h5>
                </div>
                <h4 style={{ fontWeight: 700 }}>Add a resume for {jobtitle}</h4>
                <form >

                    <div className="document-option" onClick={handleChoose}>
                        <input type="file" name="resume" onChange={handleChange} ref={inputRef} style={{ display: 'none' }} />
                        <BsFileEarmarkArrowUp className="upload-icon" />
                        <section>
                            <h5 style={{ margin: 0, padding: 0 }}>Upload resume</h5>
                            <p>Use a pdf, docx, doc, rtf and txt</p>
                        </section>
                        <TiTick className="tick-icon" />
                    </div>

                    <div className="form-actions">
                        <h5
                            onClick={() => { navigate('/') }}
                            className="return-link">
                            Return to job search
                        </h5>
                        <button onClick={handleContinue2}>Continue</button>
                    </div>
                </form>
            </div>} */}

            {step2 && <div className="common-container ">
                <ProgressBar percent={50} unfilledBackground="gray" filledBackground="navy" className="progress-bar" />

                <div className="step-header">
                    <BiArrowBack id="arrow" onClick={handleContinue1} />
                    <h5>Application step 2 of 4</h5>
                </div>
                <h4 style={{ marginTop: 6, marginBottom: 25 }}>Enter a past job that shows relevant experience</h4>
                <h5>Relevant experience <span>(optional)</span></h5>
                <form>
                    <label htmlFor="jobTitle">Job title</label>
                    <input type="text" name="currentjobTitle" onChange={handleChange} />
                    <label htmlFor="company">Company</label>
                    <input type="text" name="currentCompany" onChange={handleChange} />
                    <label htmlFor="experience">Experience </label><span>(in years)</span>
                    <input type="number" name="experience" onChange={handleChange} />

                    <div className="form-actions">
                        <h5 onClick={() => { navigate('/') }}>Exit</h5>
                        <button onClick={handleContinue2}>Continue</button>
                    </div>
                </form>
            </div>}

            {step3 && (
                <div className="common-container ">
                    <ProgressBar percent={75} unfilledBackground="gray" filledBackground="navy" className="progress-bar" />

                    <div className="step-header">
                        <BiArrowBack id="arrow" onClick={handleContinue2} />
                        <h5>Application step 3 of 4</h5>
                    </div>
                    <h4>Want to include any supporting documents?</h4>
                    <h5>
                        Cover letter <span>(optional)</span>
                    </h5>
                    <form>
                        <div className="document-option" onClick={() => setCoverLetter(false)}>
                            <CgFileRemove />
                            <section>
                                <h5>Apply without cover letter</h5>
                                <p>Cover letter is optional for this job</p>
                            </section>
                            {!coverLetter && <TiTick className="tick-icon" />}
                        </div>
                        <div className="document-option" onClick={() => setCoverLetter(true)}>
                            <FaFileSignature />
                            <section>
                                <h5>Write cover letter</h5>
                                <p>Explain how you're a good fit</p>

                            </section>
                            {coverLetter && <TiTick className="tick-icon" />}
                        </div>

                        {coverLetter && (
                            <div className="form-actions">
                                <textarea
                                    name="coverLetter"
                                    onChange={handleChange}
                                    placeholder="Write your cover letter here..."
                                    rows="10"
                                    style={{ width: "100%", marginTop: "1rem" }}
                                />
                            </div>
                        )}

                        <div className="form-actions">
                            <h5 onClick={() => { navigate('/') }}>Exit</h5>
                            <button onClick={handleReview}>Review your application</button>
                        </div>
                    </form>
                </div>
            )}

            {review &&
                <ReviewPage formData={formData} handlereviewback={handlereviewback} />
            }
            <p className="issue">Having an issue with this application?<span>Tell us more</span></p>
        </>
    );
}

export default ApplyForm;
