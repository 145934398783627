import React, { useEffect, useState } from "react";
import "./mediaview.css";

const MediaViewer = ({ pdf,setview }) => {


  const handleClick = (e) => {
    if (e.target.classList.contains("FullscreenOverlay")) {
      setview(false);
    }
  };

  return (
    <div className="MediaViewer">
      <div className="FullscreenOverlay" onClick={handleClick}>
        <iframe
          className="FullScreenDoc"
          src={pdf}
          title="PDF Viewer"
          controlsList="nodownload"
        />
      </div>
    </div>
  );
};

export default MediaViewer;
