import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, TextField, Card, CardContent, CardActions } from '@mui/material';
import { getResumeApi, addResumeApi, updateResumeApi, deleteResumeApi } from '../../Api/StudentsApi';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MediaViewer from '../DocsViewer/MediaViewer';
import { getUserInfo } from '../../Api/userApi';

const Resume = () => {
    const [view, setview] = useState(false);
    const [resumeURl, setresumeURl] = useState(null);
    const [resume, setResume] = useState(null);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(null);

    const fetchResume = async () => {
        const checkresume = await getUserInfo();

        if (checkresume && checkresume.status === 'success' && checkresume.user.resume) {
            setResume(checkresume.user.resume);
        }
    };

    useEffect(() => {
        fetchResume();
    }, []);


    const getResume = async () => {
        const response = await getResumeApi();
        if (response && response.status === 'success') {
            setresumeURl(response.file);
            setview(true);
        }
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('resume', file);

        const response = resume ? await updateResumeApi(formData) : await addResumeApi(formData);
        if (response && response.status === 'success') {
            setMessage({ type: 'success', text: resume ? 'Resume updated successfully!' : 'Resume uploaded successfully!' });
            fetchResume();
        }
    };

    const handleDelete = async () => {
        const response = await deleteResumeApi(resume.userID);
        if (response && response.status === 'success') {
            setMessage({ type: 'success', text: 'Resume deleted successfully!' });
            setResume(null);
        }
    };

    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>
                Resume
            </Typography>

            {message && (
                <Alert severity={message.type}>
                    <AlertTitle>{message.type === 'success' ? 'Success' : 'Error'}</AlertTitle>
                    {message.text}
                </Alert>
            )}

            {!resume ? (
                <Box>
                    <Typography variant="h6">No resume found. Please upload your resume.</Typography>
                    <input
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ margin: '20px 0' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleUpload} disabled={!file}>
                        Upload Resume
                    </Button>
                </Box>
            ) : (
                <Card>
                    <CardContent onClick={getResume}>
                        <Typography variant="h6">Current Resume</Typography>
                        <Typography variant="body2">{resume} </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" component="label">
                            Update Resume
                            <input type="file" hidden accept=".pdf,.doc,.docx" onChange={handleFileChange} />
                        </Button>
                        {file && <Button variant="contained" color="secondary" onClick={handleUpload} disabled={!file}>
                            Submit
                        </Button>}
                        <Button variant="outlined" color="error" onClick={handleDelete}>
                            Delete Resume
                        </Button>
                    </CardActions>
                </Card>
            )}
            {view && <MediaViewer pdf={resumeURl} setview={setview} />}
        </Container>
    );
};

export default Resume;
