import React, { useState } from 'react';
import { BiArrowBack } from "react-icons/bi";
import { VscFilePdf } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import './ReviewPage.css';
import { applyJobsApi } from '../../../../Api/StudentsApi';

const ReviewPage = ({ handlereviewback, formData }) => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleSubmit = async () => {
        const response = await applyJobsApi(formData);
        if (response && response.status === 'success') {
            setIsDialogOpen(true);
        }
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        navigate('/');
    }

    return (
        <div className="review-container">
            <div id="prog"></div>
            <div className="backArrow">
                <BiArrowBack id="arrow" onClick={handlereviewback} />
                <h6>Application step 4 of 4</h6>
            </div>
            <h2>Please review your application</h2>
            <h4>Contact information</h4>
            <div className="contactInfo">
                <div>
                    <p>Full Name</p>
                    <h3>{`${formData.firstName} ${formData.lastName}`}</h3>
                </div>
                <div>
                    <p>Email Address</p>
                    <h3>{formData.email}</h3>
                    {/* <small>
                        To mitigate fraud, We may mask your email address. If masked, the employer will see an address like 
                        <strong>{formData.email}</strong>. Some employers, however, may still be able to unmask and see your actual email address.
                    </small> */}
                </div>
                <div>
                    <p>City, State</p>
                    <h3>{formData.city}</h3>
                </div>
                <div>
                    <p>Phone Number</p>
                    <h3>{formData.phoneNumber}</h3>
                </div>
            </div>

            {/* <h4>Resume</h4>
            <div className="resume">
                <VscFilePdf />
                <h3>{formData.resume?.slice(12)}</h3>
            </div> */}

            <h4>Employee questions</h4>
            <div className="questions">
                <div>
                    <p>Job Profile</p>
                    <h3>{formData.currentjobTitle}</h3>
                </div>
                <div>
                    <p>Company name</p>
                    <h3>{formData.currentCompany}</h3>
                </div>
                <div>
                    <p>How many years of total work experience do you have?</p>
                    <h3>{formData.experience}</h3>
                </div>
            </div>

            <div className="actions">
                <h4 onClick={handlereviewback}>Exit</h4>
                <button onClick={handleSubmit}>
                    <Link style={{ color: "white" }}>Submit your application</Link>
                </button>
            </div>
            <p className="textCenter">Having an issue with this application?<span>Tell us more</span></p>

            {isDialogOpen && (
                <div className="dialog">
                    <div className="dialog-content">
                        <h3>Your application was successful</h3>
                        <p>You will receive a confirmation email shortly.</p>
                        <button onClick={handleCloseDialog}>Go to Job Page</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReviewPage;
